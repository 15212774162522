import { NgModule } from '@angular/core';
import { NewsletterComponent } from './newsletter.component';
import { MatInputModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  declarations: [NewsletterComponent],
  exports: [NewsletterComponent]
})
export class NewsletterModule { }
