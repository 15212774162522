import { NgModule } from '@angular/core';
import { ContactComponent } from './contact.component';
import { MatInputModule, MatSelectModule, MatButtonModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ae2ContactFormModule } from '@angularecommerce/core/components/contact-form';
import { Ae2MaskModule } from '@angularecommerce/core/directives/mask';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule, 
    MatSelectModule,
    MatButtonModule,
    FlexLayoutModule,
    RouterModule,
    Ae2ContactFormModule,
    Ae2MaskModule
  ],
  declarations: [ContactComponent],
  exports: [ContactComponent]
})
export class ContactModule { }
